// src/pages/contact.js
import * as React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Contact from '../components/Contact0/Contact';
import Layout from '../components/layout';
import Seo from '../components/seo';

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/contact', crumbLabel: 'お問い合わせ' },
        ]}
      />
      <Contact apiUrl="https://nanocms.digitalcreative.co.jp/contact" />
    </Layout>
  );
};

export default ContactPage;

export const Head = () => (
  <Seo
    title="お問い合わせ"
    description="デジタルクリエイティブ株式会社のお問い合わせはこちら。"
    cover="cover/cover-contact.jpg"
  />
);
