import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Footer from '../components/Footer6/Footer';
import Headers from '../components/Header101/Header';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          companyName
        }
      }
    }
  `);

  // サイトのメタデータから会社名を抽出
  const companyName = data.site.siteMetadata.companyName;

  return (
    <div>
      <Headers
        logoImage="/logo006.svg"
        title={companyName}
        items={[
          {
            id: 1,
            name: 'ニュース',
            link: '/#news',
            dropdown: false,
          },
          {
            id: 2,
            name: 'Web制作',
            dropdown: true,
            subItems: [
              { name: 'コラム一覧', link: '/pages' },
              { name: '料金、受付の流れ', link: '/flow' },
              { name: 'カタログ、制作実績', link: '/catalog' },
            ],
          },
          {
            id: 3,
            name: '企業概要',
            dropdown: true,
            link: '/about',
            subItems: [
              { name: '企業概要', link: '/about' },
              { name: '企業理念', link: '/philosophy' },
              { name: '事業内容', link: '/business' },
            ],
          },
          {
            id: 5,
            name: 'コンタクト',
            dropdown: true,
            subItems: [
              {
                name: 'お問い合わせ',
                link: '/contact',
              },
              { name: 'ミーティングの予約', link: '/meeting' },
              {
                name: 'よくある質問',
                link: '/faq',
              },
            ],
          },
        ]}
      />
      {children}
      <Footer
        items={[
          {
            categoriesname: '企業情報',
            categories: [
              { name: '企業概要', link: '/about' },
              { name: '企業理念', link: '/philosophy' },
              { name: '事業内容', link: '/business' },
            ],
          },
          {
            categoriesname: 'Web制作',
            categories: [
              { name: 'コラム一覧', link: '/pages' },
              { name: '料金、受付の流れ', link: '/flow' },
              { name: 'カタログ、制作実績', link: '/catalog' },
            ],
          },
          {
            categoriesname: 'コンタクト',
            categories: [
              {
                name: 'お問い合わせ',
                link: '/contact',
              },
              { name: 'ミーティングの予約', link: '/meeting' },
              { name: 'プライバシーポリシー', link: '/privacy' },
            ],
          },
        ]}
        logo="/logo006.svg"
        logoAlt="デジタルクリエイティブ株式会社"
        copyright="デジタルクリエイティブ株式会社"
        logoSrc="/"
      />
    </div>
  );
};

export default Layout;
